import { createSelector, createSlice } from "@reduxjs/toolkit";
import { reuseProductThunks } from "./reuseProductsThunkAction";

const initialState = {
    reuseProducts: {},
    status: 'idle',
    error: null
}

/* Product Slice configuration */
const reuseProductSlice = createSlice({
    name: 'reuseProducts',
    initialState,
    reducers: {
        resetReuseProductAuthData: () => initialState,
        updateAddedReuseWishlistStatus: (state, action) => {
            const { productId, addedWishlist } = action.payload;
            const reuseProductsListData = state.reuseProducts?.body?.content;
            let index = reuseProductsListData && reuseProductsListData?.findIndex(product => product?.id === productId);
            if (index > -1) {
                state.reuseProducts.body.content[index].addedWishlist = addedWishlist;
            }
        },
        updateReuseProductRequestStatus: (state, action) => {
            const { productId } = action.payload;
            const reuseProductsListData = state.reuseProducts?.body?.content;
            let index = reuseProductsListData && reuseProductsListData?.findIndex(product => product?.id === productId);
            if (index > -1) {
                state.reuseProducts.body.content[index].productRequested = true;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(reuseProductThunks.fetchReuseProduct.pending, (state, action) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(reuseProductThunks.fetchReuseProduct.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newProducts = action.payload || {};
                state.reuseProducts = newProducts;
            })
            .addCase(reuseProductThunks.fetchReuseProduct.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload;
            })
            .addCase(reuseProductThunks.fetchMoreReuseProducts.pending, (state, action) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(reuseProductThunks.fetchMoreReuseProducts.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newProducts = action.payload?.body;
                const newProductsData = action.payload?.body?.content || [];
                state.reuseProducts.body = {...newProducts, content: [...state.reuseProducts?.body?.content, ...newProductsData] };
            })
            .addCase(reuseProductThunks.fetchMoreReuseProducts.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload;
            })
    }
})

export const getReuseProductsSelector = createSelector((state) => state?.reuseProducts, (reuseProducts) => reuseProducts);
export const { resetReuseProductAuthData, updateAddedReuseWishlistStatus, updateReuseProductRequestStatus } = reuseProductSlice.actions;
export default reuseProductSlice.reducer;



