import { DeviceUUID } from 'device-uuid';
import { v4 as uuidv4 } from 'uuid';
import { auth_service } from "../app/authService";
import addDays from 'date-fns/addDays'
import * as FileSaver from "file-saver";

export const checkCharacterLength = (string,characterCount) =>{
	let count = string.match(/\S/g).length;
    if(count >= characterCount){
        return true
    }
    return false
    
}

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const uppercaseTest = new RegExp("^(?=.*[A-Z])");
export const minCharacterTest = (minLength) => new RegExp(`^(?=.{${minLength}})`);
export const alphanumericRegex = new RegExp("^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]{10}$");
export const gstRegex = new RegExp('[0-9]{2}[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}');
export const dusnRegex = new RegExp("^(?=.{9})");
export const specialCharPattern = /[^a-zA-Z0-9]/;
export const digitPattern = /\d/;
export const pincodeRegex = new RegExp(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/);

const loginUserData = auth_service.getUserDetails();
const currentUserData = auth_service.getCurrentUserData();

export const getUserDataFromUrlParams = () => {
    const urlParams = new URLSearchParams(window?.location?.search);
    const email = urlParams.get('email');
    const code = urlParams.get('code');
    const type = urlParams.get('type');
    const instituteId = urlParams.get('instituteId');
    const status = urlParams.get('status');
    const authToken = urlParams.get('authToken'); 
    const loginType = urlParams.get('loginType');
    let userParamsData = {
        email: email,
        code: code,
        userType: type,
        instituteId: instituteId,
        status: status,
        authToken: authToken,
        loginType: loginType
    }
    return userParamsData;
}

export const studentClassData = ["THREE", "FOUR", "FIVE", "SIX", "SEVEN", "EIGHT", "NINE", "TEN", "ELEVEN", "TWELVE", "NA"];

export const studentClassLevel = (value, flag) => {
    return (!flag && value !== "NA" ? "Class " : "") + (value === "THREE" ? "3" : value === "FOUR" ? "4" : value === "FIVE" ? "5" : value === "SIX" ? "6" :
        value === "SEVEN" ? "7" : value === "EIGHT" ? "8" : value === "NINE" ? "9" : value === "TEN" ? "10" :
            value === "ELEVEN" ? "11" : "12");
}

export const studentClassObj = [{ "label": "Class 3", value: "THREE" }, { "label": "Class 4", value: "FOUR" }, { "label": "Class 5", value: "FIVE" }, { "label": "Class 6", value: "SIX" },
{ "label": "Class 7", value: "SEVEN" }, { "label": "Class 8", value: "EIGHT" }, { "label": "Class 9", value: "NINE" }, { "label": "Class 10", value: "TEN" }, { "label": "Class 11", value: "ELEVEN" },
{ "label": "Class 12", value: "TWELVE" }, { "label": "NA", "value": "NA" }]

export const getStudentClassData = (value) => {
    let data = studentClassObj && studentClassObj.find((item) => item.value === value);
    return data?.label || null
}

export const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

export const convertFirstCapital = (input) => {
    if (!input) {
        return input;
    }
    let output = input.replace(/%u(\w{4,4})/g, function (a, b) {
        var charcode = parseInt(b, 16);
        return String.fromCharCode(charcode);
    });
    output = output.replace(/\\u(\w{4,4})/g, function (a, b) {
        var charcode = parseInt(b, 16);
        return String.fromCharCode(charcode);
    });
    return output.charAt(0).toUpperCase() + output.slice(1);
}

export const dateFormatFunction = (date) => {
    let options = [{ day: 'numeric' }, { month: 'short' }, { year: 'numeric' }];
    function format(option) {
        let formatter = new Intl.DateTimeFormat('en', option);
        return formatter.format(date);
    }
    return options.map(format).join(" ");
}

export const epochTogmt = (date) => {
    let date1 = new Date(date);
    let day = date1.getDate();
    let month = date1.getMonth() + 1;
    let year = date1.getYear();
    return month > 12 ? 0 : month + "/" + day + "/" + year.toString().substr(-2);
}

export const messagePopUp = (message) => {
    return ({
        title: `${message}`,
        focusConfirm: false,
        type: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
    })
};

export const getAttachmentFileName = (url) => {
    var parsedUrl = url.substring(url?.lastIndexOf("/") + 1);
    let filename = parsedUrl?.substring(parsedUrl?.indexOf("-") + 1);
    return filename;
}

export const addLeadingZero = (number) => (number > 9 ? number : `0${number}`)

export const getCapitalizeFirstletter = (str) => {
    let capitalizedStr = str?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    return capitalizedStr;
};

export const capitalizeFirstLetter= (string) => {
    return string?.length > 0 ? (string[0].toUpperCase() + string.slice(1)) : "";
}

export const getClassValue = (classLabel) => {
    return classLabel?.replace(/Class /g, "");
}

export const getUserTypeUrl = (type) => {
    return type === "INSTITUTE" ? "institution" : type === "CANDIDATE" ? "student" : "profile";
}

export const minimumArticlesWarningModal = () => {
    return ({
        title: `You have already read minimum articles`,
        focusConfirm: false,
        type: 'warning',
        showCancelButton: true,
        showDenyButton: false,
        confirmButtonText: 'Take a Quiz',
        cancelButtonText: 'Continue reading',
        allowOutsideClick: false,
    })
};

export const boardsNames = ["CISCE", "IB", "CBSE", "State Board"]

export const analyticsDateFilter = [
    { value: "TODAY", label: "TODAY" },
    { value: "YESTERDAY", label: "YESTERDAY" },
    { value: "LAST7DAYS", label: "LAST 7 DAYS" },
    { value: "LAST30DAYS", label: "LAST 30 DAYS" },
    { value: "LAST90DAYS", label: "LAST 90 DAYS" },
    { value: "LAST365DAYS", label: "LAST 365 DAYS" }
]

export const quizSummaryFilter = [
    { value: "TODAY", label: "TODAY" },
    { value: "LAST_DAY", label: "YESTERDAY" },
    { value: "LAST_7_DAYS", label: "LAST 7 DAYS" },
    { value: "LAST_1_MONTH", label: "LAST 30 DAYS" },
    { value: "LAST_90_DAYS", label: "LAST 90 DAYS" },
    { value: "LAST_365_DAYS", label: "LAST 365 DAYS" }
]

export const searchCategories = ["Class", "Name", "School ID"]

export const sectionOptions = [
    { value: 'NA', label: 'NA' },
    { value: 'A', label: 'A' },
    { value: 'B', label: 'B' },
    { value: 'C', label: 'C' },
    { value: 'D', label: 'D' },
    { value: 'E', label: 'E' },
    { value: 'F', label: 'F' },
    { value: 'G', label: 'G' }
];

export const confimSubscriptionModalSwal = (message) => {
    return ({
        title: `${message}`,
        focusConfirm: false,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Subscribe Now',
        cancelButtonText: 'Later',
        allowOutsideClick: false,
    })
};

export const deviceId = () => {
    const Deviceuuid = new DeviceUUID().get() + '-' + uuidv4();
    if (!localStorage.getItem("deviceuuID")) {
        localStorage.setItem("deviceuuID", Deviceuuid);
    }
    return Deviceuuid;
};

export const confimSubmitModalSwal = (message, notShowCancelFlag) => {
    return ({
        title: `${message}`,
        focusConfirm: false,
        type: 'warning',
        showCancelButton: !notShowCancelFlag ? true : false,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        allowOutsideClick: false,
    })
};

export const confirmProductSubmitModalSwal = (message) => {
    return ({
        title: `${message}`,
        focusConfirm: false,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Proceed',
        cancelButtonText: 'Cancel',
        allowOutsideClick: false,
    })
};

export const getHours = (n) => {
    var num = parseInt(n);
    var hours = (num / 60);
    return Math.floor(hours);
}

export const getMinutues = (n) => {
    var num = parseInt(n);
    var hours = (num / 60);
    var minutes = (hours - Math.floor(hours)) * 60;
    return Math.round(minutes);
}

export const numberFormatter = (labelValue) => {
    return Math.abs(Number(labelValue)) >= 1.0e+9 ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(1) + "B" :
        Math.abs(Number(labelValue)) >= 1.0e+6 ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(1) + "M" :
            Math.abs(Number(labelValue)) >= 1.0e+3 ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(1) + "K" : Math.abs(Number(labelValue));
}

export const sectionTitleMap = {
    LATEST: "Latest News",
    PUBLICATION: "Your Publication Mix",
    RECENT_READS: "Your Recent Reads",
    RECOMMEND_READS: "Recommended Reads",
    default: ""
};

export const pageTitleMap = {
    LATEST: "Latest News",
    TRENDING: "Trending News",
    FOR_YOU: "For You",
    PUBLICATION: "All Publishers",
    RECENT_READS: "Recent Reads",
    default: ""
};

export const pageIconClassMap = {
    LATEST: "iLatest",
    TRENDING: "iTrending",
    FOR_YOU: "iFor-You",
    PUBLICATION: "iPublisher",
    RECENT_READS: "",
    default: ""
};

export const sectionPathMap = {
    LATEST: "/latest",
    PUBLICATION: "/publisher",
    RECENT_READS: "/recent-reads",
    RECOMMEND_READS: "/for-you",
    default: "javascript:void(0)"
};

export const isWebView = () => {
    var standalone = window.navigator.standalone,
        userAgent = window.navigator.userAgent.toLowerCase(),
        safari = /safari/.test(userAgent),
        ios = /iphone|ipod|ipad/.test(userAgent);

    if (ios) {
        if(!standalone && !safari) {
            return true
        }
        return false;
    } else {
        if(userAgent.includes('wv')) {
            return true
        }
        return false;
    };
}

export const closeModalBackDrop = () => {
    window.$("#reviewPopup").modal("hide");
    window.$('body').removeClass('modal-open');            
    document.body.removeAttribute('style');
    const backdrop = document.querySelector('.modal-backdrop');
    if(backdrop) {
        backdrop.remove();
    }
}

export const publicationMessage = "The logos featured herein are proprietary trademarks belonging to their respective publication houses. Newsepick neither possesses nor asserts any ownership, control, or authorization over the Intellectual Property represented by these logos. Any reference to, or use of, these logos is made strictly for identification and informational purposes, without the intention of infringing upon the rightful ownership or Intellectual Property rights of the respective publication houses"

export const filterSelectionMap = {
    CATERGORY: "Category",
    PUBLISH_DATE: "Publish Date",
    PUBLICATION: "Publication",
    TRENDING: "Trending",
    VIEWS: "Views",
    default: ""
};
export const  analyticsViewImpressionOptions = [
    { value: "VIEW", label: "View" },
    { value: "IMPRESSION", label: "Impression" },

]

export const getEpochTimeStamp = (days) => {
    return addDays(new Date().getTime(), -days)?.getTime();
}

export const publicationDateFilterOptions = [
    { value: "WITH_IN_24_HOURS", label: "With in 24 Hours", id: uuidv4() },
    { value: "7_DAYS", label: "7 Days", id: uuidv4()  },
    { value: "30_DAYS", label: "30 Days", id: uuidv4() },
    { value: "90_DAYS", label: "90 Days", id: uuidv4() },
    { value: "THIS_YEAR", label: "This year", id: uuidv4()  },
]

export const redirectToNewsepickWeb = (pageUrl, flag) => {
    let url = process.env.REACT_APP_newsePickFeURL;
    if(process.env.REACT_APP_ENV === "prod") {
        url = "https://www.newsepick.com";
    }
    if(flag) {
        window.open(`${url}/${pageUrl}`, '_blank');
    } else {
        window.location.href = `${url}/${pageUrl}`;
    }
}
export const redirectToAppNewsepickPage = (pageUrl, flag) => {
    let url = process.env.REACT_APP_newsePickWebURL;
    if(process.env.REACT_APP_environment === "prod") {
        url = "https://app.newsepick.com";
    }
    if(flag) {
        window.open(`${url}/${pageUrl}`, '_blank');
    } else {
        window.location.href = `${url}/${pageUrl}`;
    }
}

export const filterMessageMap = {
    CATERGORY: "You can select the category, and we will curate your latest and recommended feeds with relevant news in the selected category",
    PUBLISH_DATE: "You can select the news publish period and we will filter them in trending, your publication and recommended feeds for the selected period.",
    TRENDING: "You can filter the most trending news in any category, and we will curate it for your trending feeds for the selected category",
    PUBLICATION: "You can select your preferred publications, and we will curate latest and recommended feeds for the selected publications",
    VIEWS: "You can select to filter out the latest, recommended and your publication feeds according to their views stats on newsepick.",
    default: ""
};

export const checkInstituteProfile = () => {
    return loginUserData?.type === "INSTITUTE" || currentUserData?.type === "INSTITUTE";
}

export const checkCandidateProfile = () => {
    return loginUserData?.type === "CANDIDATE" || currentUserData?.type === "CANDIDATE";
}

export const checkNotFacultyProfile = () => {
    return loginUserData?.userType !== "FACULTY" && currentUserData?.userType !== "FACULTY";
}

export const shopSortByFilterOptions = [
    { label: "Latest", value: "LATEST" },
    {label: "Price, low to high", value: "PRICE_LOW_TO_HIGH"},
    {label: "Price, high to low", value: "PRICE_HIGH_TO_LOW"},
];

export const hasFiltersChanged = (currentState) => {
    const initialFilterState = { categoryFilterData: [], brandsFilterData: [], priceRange: [0, 1000], coinRange: [0, 1000], isEnableCoinsfilter: false, sortByFilter: "LATEST"};
    return (
        currentState?.priceRange[0] !== initialFilterState.priceRange[0] ||
        currentState?.priceRange[1] !==  currentState?.globalMaxValue  ||
        currentState?.coinRange[0] !== initialFilterState.coinRange[0] ||
        currentState?.coinRange[1] !==  currentState?.globalCoinMaxValue  ||
        currentState?.categoryFilterData.some(item => item?.selected !== initialFilterState?.categoryFilterData.some(i => i.id === item.id && i.selected)) ||
        currentState?.brandsFilterData.some(item => item.selected !== initialFilterState?.brandsFilterData.some(i => i.id === item.id && i.selected)) ||
        currentState?.isEnableCoinsfilter !== initialFilterState?.isEnableCoinsfilter ||
        currentState?.sortByFilter !== initialFilterState?.sortByFilter
    );
};

export const generateSlug = (productName) =>  {
    let productSlug = productName?.toLowerCase()?.replace(/[^a-z0-9\s-]/g, '')?.replace(/\s+/g, '-')?.trim();   
    return productSlug;                          
};

export const getTotalAmounts = (cartData, field) => {
    let calculateTotals = cartData && cartData?.reduce((total, item) => {return total + item[field]}, 0);
    return calculateTotals || 0 ;
};

export const redemptionSearchCategories = [{label: "Redemption Code", value: "redemptionCode"}, {label: "Shipment Status", value: "shipmentStatus"}]

export  const delivaryLocationData = [
    { value: 'NEWSEPICK_WARE_HOUSE', label: 'Newsepick Warehouse' },
   /*   { value: 'office', label: 'Office' },
        { value: 'home', label: 'Home' },
    */
];
export const checkoutErrorMessageMap = {
    INSUFFICIENT_QUANTITY: "Insufficient Product Quantity",
    INSUFFICIENT_AMOUNT: "It looks like your wallet balance is too low to complete this transaction. Please add funds to your wallet and try again",
    INSUFFICIENT_COIN: (
        <>
            It looks like your Newsepick Coin balance is too low to complete this transaction. Please try again when you have sufficient balance.<br /><br />
            Tips to accumulate coins: <br /><span className='fw-normal'>Read quiz articles and play Quiz regularly.</span>
        </>
    )
};


export const downloadPDF = async (e, fileSrc) => {
    e.preventDefault();
    const response = await fetch(fileSrc, { mode: 'no-cors', responseType: "blob" });
    const blobImage = await response.blob();
    const href = URL.createObjectURL(blobImage);
    var parsedUrl = fileSrc.substring(fileSrc.lastIndexOf("/") + 1);
    let filename = parsedUrl.substring(parsedUrl.indexOf('_') + 1)
    const anchorElement = document.createElement('a');
    anchorElement.href = href;
    FileSaver.saveAs(fileSrc, filename);
};