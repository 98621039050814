import { createSelector, createSlice } from "@reduxjs/toolkit";
import { requestCountThunk } from "./requestCountThunkAction";

const initialState = {
    count: 0,
};

const requestCountSlice = createSlice({
    name: 'requestCount',
    initialState,
    reducers: {
        decrementRequestCount: (state) => {
            state.count -= 1;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(requestCountThunk.fetchRequestCount.pending, (state, action) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(requestCountThunk.fetchRequestCount.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const requestCount = action.payload.body || 0;
                state.count = requestCount;
            })
            .addCase(requestCountThunk.fetchRequestCount.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload;
            })
    }
});

export const getRequestCountSelector = createSelector(((state) => state?.requestCount?.count), (count) => count);
export const { decrementRequestCount } = requestCountSlice.actions;
export default requestCountSlice.reducer;