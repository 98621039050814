import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Apis } from "../../config";

const fetchWishListSaveItems = createAsyncThunk('wishlist/fetchWishListSaveItems', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetWishListSaveItems(data))
        return response?.data
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch wish list items');
    }
});

const fetchReuseWishListSaveItems = createAsyncThunk('wishlist/fetchReuseWishListSaveItems', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetReuseWishListData(data))
        return response?.data
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch wish list items');
    }
});

export const wishListThunks = { fetchWishListSaveItems, fetchReuseWishListSaveItems };

