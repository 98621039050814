import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Apis } from "../../config";

const fetchRequestCount = createAsyncThunk('fetch/requestCount', async (userId, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetRequestCount(userId))
        return response?.data
    } catch (err) {
        return rejectWithValue(err?.message)
    }
});

export const requestCountThunk = { fetchRequestCount };
