import { createSelector, createSlice } from "@reduxjs/toolkit";
import { wishListThunks } from "./wishListThunkAction";

let initialState = {
    reuseWishListCount: 0,
    reuseWishListSavedItems: {},
    status: 'idle',
    error: null
};

const reuseWishListSlice = createSlice({
    name: 'reuseWishlist',
    initialState,
    reducers: {
        updateReuseWishlistCount: (state, action) => {
            const reuseActivityCount = action.payload || 0;
            state.reuseWishListCount = reuseActivityCount;
        },
        incrementReuseWishlistCount: (state) => {
            state.reuseWishListCount += 1;
        },
        decrementReuseWishlistCount: (state) => {
            state.reuseWishListCount -= 1; 
        },
        handleReuseWishlistStateChange: (state, action) => {
            const { productId, actionType, addedCartlist } = action.payload;
            let index = state.reuseWishListSavedItems?.body && state.reuseWishListSavedItems?.body?.findIndex(item => item?.productId === productId);
            if(index > -1){
                if (actionType === "REMOVE") {
                    state.reuseWishListSavedItems.body.splice(index, 1);
                }else{
                    state.reuseWishListSavedItems.body[index].addedCartlist = addedCartlist;
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(wishListThunks.fetchReuseWishListSaveItems.pending, (state, action) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(wishListThunks.fetchReuseWishListSaveItems.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newWishListSavedItems = action.payload || {};
                state.reuseWishListSavedItems = newWishListSavedItems;
            })
            .addCase(wishListThunks.fetchReuseWishListSaveItems.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload;
            })
    }
})

export const { updateReuseWishlistCount, incrementReuseWishlistCount, decrementReuseWishlistCount, handleReuseWishlistStateChange } = reuseWishListSlice.actions;
export const getReuseWishListCountSelector = createSelector((state) => state?.reuseWishlist?.reuseWishListCount, (reuseWishListCount) => reuseWishListCount);
export const getReuseWishListSelector = createSelector((state) => state?.reuseWishlist, (reuseWishlist) => reuseWishlist);
export default reuseWishListSlice.reducer;

